'use client';

import { FC, useEffect } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box } from '@mui/system';
import { Button } from 'modules/theme/components/button/Button';
import { useRouter } from 'next/navigation';
import { Theme, useMediaQuery } from '@mui/material';
import { Figure } from 'modules/theme/components/assets/blankPlaceholder/Figure';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import PageView from 'components/analytics/PageView';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { EGtmEvent } from 'modules/gtmEvents/enums/EGtmEvent';
import { ETrackEventCategory } from 'modules/gtmEvents/enums/ETrackEventCategory';
import { usePageViewEvent } from 'modules/gtmEvents/hooks/pageView/usePageViewEvent';

interface IProps {
    errorPageText: string
    mainButtonText: string
}

const Main: FC<IProps> = ({ errorPageText, mainButtonText }) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const router = useRouter();
    const { gtm: { sendEvent }} = useAnalytics();
    usePageViewEvent();

    const redirectToContactsPage = () => {
        router.push(`${AppConfigService.getNehnutelnostiUrl()}/kontakty`);
    };

    const redirectHome = () => {
        router.push('./');
    };

    useEffect(() => {
        sendEvent({
            'event': EGtmEvent.TRACK_EVENT,
            'category': ETrackEventCategory.ERROR,
            'action': window.location.href,
        });
    }, [sendEvent]);

    return (
        <>
            <PageView/>
            <Box
                height='80%'
                width='100%'
                display='flex'
                paddingLeft={2}
                paddingRight={2}
                // NOTE: prevent sunlight from getting cut by header.
                mt={4}
                justifyContent='center'
            >
                <Box
                    maxWidth={460}
                    display='flex'
                    justifyContent='center'
                    flexDirection='column'
                >
                    <Figure width={120} />
                    <Box mt={2} mb={4}>
                        <Text variant='h1' semibold>{errorPageText}</Text>
                    </Box>
                    <Box
                        width='100%'
                        mb={1}
                        sx={{
                            '& .MuiButtonBase-root': {
                                height: 64,
                                borderRadius: 5,
                            }
                        }}
                    >
                        <Button
                            text={mainButtonText}
                            small={isMobile ? true : false}
                            onClick={redirectHome}
                            secondary
                            fullWidth
                        />
                    </Box>
                    <Box
                        width='100%'
                    >
                        <Button
                            text={<Box fontWeight={600}>Kontaktovať podporu</Box>}
                            small={isMobile ? true : false}
                            onClick={redirectToContactsPage}
                            textBtn
                            fullWidth
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Main;
